import React, {useEffect, useState} from "react";
import {Platform, TouchableOpacity} from "react-native";
import Upload from "@assets/icons/upload.svg";
import TextInput from "@components/Forms/TextInput";
import CurrencyInputStyled from "@components/Forms/CurrencyInput";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import RentalDates from "@pages/SignedIn/ProductionPayment/components/RentalDates";
import FlexRow from "@components/Views/FlexRow";
import {toTwoDecimal, transformTailwindToHex} from "@src/helpers";
import * as DocumentPicker from 'expo-document-picker';

export default function KitRental({data, onUpdateData, productionId}) {

    const [paymentDocument, setPaymentDocument] = useState<any>(null);
    const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
    const [rentalDates, setRentalDates] = useState<string[]>([]);
    const [paymentNotes, setPaymentNotes] = useState<string>("");

    useEffect(() => {
        if (data.amount !== undefined) {
            setPaymentAmount(data.amount);
        }

        if (data.note !== undefined) {
            setPaymentNotes(data.note);
        }

        if (data.extra !== undefined) {
            setRentalDates(data.extra.rental_dates);
        }
    }, []);

    useEffect(() => {
        onUpdateData({
            date: rentalDates.length > 0 ? rentalDates[0] : null,
            amount: (paymentAmount ?? 0)*rentalDates.length,
            note: paymentNotes,
            extra: {
                rental_dates: rentalDates,
                rate_per_day: paymentAmount
            },
            payment_document: paymentDocument
        });

    }, [paymentAmount, rentalDates, paymentNotes, paymentDocument]);

    const pickDocument = async () => {
        let documentResult = await DocumentPicker.getDocumentAsync({
            type: "*/*",
            multiple: false,
            copyToCacheDirectory: true
        });

        if (documentResult.type === 'cancel') {
            // User canceled picking a document, so just return
            return;
        }

        if (Platform.OS !== "web") {
            setPaymentDocument({
                name: documentResult.name,
                // If mimeType is not available, default to a generic type.
                type: (documentResult.mimeType) ? documentResult.mimeType : 'application/octet-stream',
                uri: Platform.OS === 'ios'
                    ? documentResult.uri.replace('file://', '')
                    : documentResult.uri,
            })
        } else {
            // On web, we need to convert the file into a blob
            const res = await fetch(documentResult.uri);
            const blob = await res.blob();
            setPaymentDocument(blob);
        }
    }

    return <Flex>
        <RentalDates label="Rental Dates" value={rentalDates} disabled={false} updateValue={setRentalDates} productionId={productionId}/>

        <Flex>
            <Flex className="absolute top-4 left-3"><Text type="xs-medium" color="denim-50">$</Text></Flex>
            <CurrencyInputStyled label="Rate (Per-Day)" value={paymentAmount} onChange={setPaymentAmount} paddingLeft="pl-6"/>
        </Flex>

        <TextInput label="List Items" value={paymentNotes} onChange={setPaymentNotes} bigText={true}/>

        <TouchableOpacity onPress={() => pickDocument()}>
            {paymentDocument == null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-denim-5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("denim-100")}/>
                    <Text type="xs-medium" color="denim-100" className="ml-2">Add Document</Text>
                </FlexRow>
            }

            {paymentDocument != null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-navy-light-100/5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                    <Text type="xs-medium" color="navy-light-100" className="ml-2">Upload Complete!</Text>
                </FlexRow>
            }
        </TouchableOpacity>

        <FlexRow verticalAlign="center" align="between" className="w-full">
            <Text type="sm-medium" color="denim-50" className="my-5">Total Payment (All Days):</Text>
            <Text type="sm-medium" color={rentalDates.length == 0 || paymentAmount == null ? "coral-100" : "navy-light-100"} className="my-5">${toTwoDecimal(rentalDates.length*(paymentAmount ?? 0))}</Text>
        </FlexRow>
    </Flex>;
}