import {Platform, ScrollView, TouchableOpacity} from "react-native";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {transformTailwindToHex} from "@src/helpers";
import Button from "@components/Buttons/Button";
import React, {useEffect, useState} from "react";
import productionApi from "@api/productionApi";
import Upload from "@assets/icons/upload.svg";
import moment from "moment";
import {MaskedTextInput} from "react-native-mask-text";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import TextInput from "@components/Forms/TextInput";
import FloatingBottom from "@components/Views/FloatingBottom";
import * as DocumentPicker from 'expo-document-picker';

export default function UploadDocs({offerId, documentsToVerify, nextStep, backStep, documentsCache, prevStage}) {

    const [loading, setLoading] = useState<boolean>(true);
    const [documentsToUpload, setDocumentsToUpload] = useState<any>([]);
    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [list, setList] = useState<string>(null);

    useEffect(() => {
        getDocuments()
    }, [])

    useEffect(() => {
        let tmpCanUpload = true;

        documentsToUpload.forEach(document => {
            if (document.file == null || document.document_number == null || document.document_number == "") {
                tmpCanUpload = false
            }

            if (document.expiration_date && (document.expiration_date_data == null || document.expiration_date_data == "")) {
                tmpCanUpload = false
            }

            if (document.country && (document.country_data == null || document.country_data == "")) {
                tmpCanUpload = false
            }
        })

        setCanUpload(tmpCanUpload);
    }, [documentsToUpload])

    const getDocuments = async () => {
        setLoading(true);

        let response = await productionApi.getOfferDocuments(offerId);
        let docs = []

        response.data.filter((doc: any) => doc.attributes.type == "upload").forEach(document => {
            if (document != null) {
                document.attributes.id = document.id;
                document.attributes.type = "upload";
                docs.push(document.attributes);
            }
        })

        Object.keys(documentsToVerify).forEach(document_key => {
            if (documentsToVerify[document_key] != null) {
                documentsToVerify[document_key].type = "verification";
                documentsToVerify[document_key].list = document_key.replace("list", "").toLowerCase();
                docs.push(documentsToVerify[document_key]);
            }
        })

        if (documentsToVerify.listA != null) {
            setList("a");
        } else if (documentsToVerify.listB != null) {
            setList("bc");
        }

        if (documentsCache != null && prevStage == "review") {
            setDocumentsToUpload(documentsCache);
        } else {
            setDocumentsToUpload(docs);
        }

        setLoading(false);
    };

    const pickDocument = async (id: string, index: number) => {
        let documentResult = await DocumentPicker.getDocumentAsync({
            type: "*/*",
            multiple: false,
            copyToCacheDirectory: true
        });

        if (documentResult.type === 'cancel') {
            // User canceled picking a document, so just return
            return;
        }

        // If a document was selected successfully
        let tmpDocumentsToUpload = [...documentsToUpload];

        tmpDocumentsToUpload[index] = {...tmpDocumentsToUpload[index], document_id: id};
        tmpDocumentsToUpload[index] = {...tmpDocumentsToUpload[index], last_updated: moment().format("YYYY-MM-DD")};

        if (Platform.OS !== "web") {
            tmpDocumentsToUpload[index] = {
                ...tmpDocumentsToUpload[index],
                file: {
                    name: documentResult.name,
                    // If mimeType is not available, default to a generic type.
                    type: (documentResult.mimeType) ? documentResult.mimeType : 'application/octet-stream',
                    uri: Platform.OS === 'ios'
                        ? documentResult.uri.replace('file://', '')
                        : documentResult.uri,
                }
            };
        } else {
            // On web, we need to convert the file into a blob
            const res = await fetch(documentResult.uri);
            const blob = await res.blob();
            tmpDocumentsToUpload[index] = {
                ...tmpDocumentsToUpload[index],
                file: blob
            };
        }

        setDocumentsToUpload(tmpDocumentsToUpload);
    };

    const uploadDocuments = async () => {
        try {
            setLoading(true);
            let uploadVerification = [];

            await documentsToUpload.forEach(async (document, index) => {
                if (document.type == "upload") {
                    await uploadDocument(document)
                } else {
                    uploadVerification.push(document);
                }
            })

            if (list != null) {
                const dataI9 = new FormData();

                dataI9.append('list', list);

                uploadVerification.forEach((document, index) => {
                    dataI9.append(`${document.list}_document_type`, document.key);
                    dataI9.append(`${document.list}_document_number`, document.document_number);
                    dataI9.append(`${document.list}_file`, document.file);

                    if (document.expiration_date) {

                        let tmp_date = document.expiration_date_data.split("-")
                        let formated_date = tmp_date[2] + "-" + tmp_date[0] + "-" + tmp_date[1]

                        dataI9.append(`${document.list}_expiration_date`, formated_date);
                    }

                    if (document.country) {
                        dataI9.append(`${document.list}_country`, document.country_data);
                    }
                })

                let response = await productionApi.postOfferUploadI9(offerId, dataI9);
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
            return;
        }

        nextStep(documentsToUpload);
    }

    const uploadDocument = async (document) => {
        const data = new FormData();

        data.append('document_id', document.id);
        data.append('expiration_date', moment().format("YYYY-MM-DD"));
        data.append('document_number', document.document_number);
        data.append('file', document.file);

        let response = await productionApi.postOfferUploadDocument(offerId, data);
    }

    return (
        <Flex className="flex-1 mt-5" verticalAlign="between">
            <ScrollView className="flex-1 px-5">
                <Flex className="flex-1">
                    {documentsToUpload.map((document, index) => <Flex className="mb-10">

                            <Text type="xs-bold" color="navy-light-100" className="mb-2">{document.title}</Text>

                            <TouchableOpacity onPress={() => pickDocument(document.id, index)}>

                                {/*{document.is_completed && document.file == null &&*/}
                                {/*    <FlexRow className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-navy-light-100/5" align="center" verticalAlign="center">*/}
                                {/*        <Upload width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>*/}
                                {/*        <Text type="xs-medium" color="navy-light-100" className="ml-2">Already Uploaded! Click here to upload again</Text>*/}
                                {/*    </FlexRow>*/}
                                {/*}*/}

                                {/*{!document.is_completed && document.file == null &&*/}
                                {document.file == null &&
                                    <FlexRow
                                        className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-coral-5"
                                        align="center" verticalAlign="center">
                                        <Upload width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                                        <Text type="xs-medium" color="coral-100" className="ml-2">Add Document</Text>
                                    </FlexRow>
                                }

                                {document.file != null &&
                                    <FlexRow
                                        className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-navy-light-100/5"
                                        align="center" verticalAlign="center">
                                        <Upload width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                                        <Text type="xs-medium" color="navy-light-100" className="ml-2">Upload
                                            Complete!</Text>
                                    </FlexRow>
                                }
                            </TouchableOpacity>

                            <TextInput
                                label="Document Number"
                                value={document.document_number}
                                onChange={(text) => {
                                    let tmpDocumentsToUpload = [...documentsToUpload];
                                    tmpDocumentsToUpload[index] = {...tmpDocumentsToUpload[index], document_number: text}
                                    setDocumentsToUpload(tmpDocumentsToUpload);
                                }}
                            />

                            {document.expiration_date &&
                                <MaskedTextInput
                                    placeholder="Expiration Date: mm-dd-yyyy"
                                    keyboardType='numeric'
                                    mask="99-99-9999"
                                    className={`h-12 px-3 pt-2 pb-3 rounded-xl text-sm mb-2 border-0.5 border-denim-20`}
                                    defaultValue={document.expiration_date_data}
                                    onChangeText={(text) => {
                                        let tmpDocumentsToUpload = [...documentsToUpload];
                                        tmpDocumentsToUpload[index] = {
                                            ...tmpDocumentsToUpload[index],
                                            expiration_date_data: text
                                        }
                                        setDocumentsToUpload(tmpDocumentsToUpload);
                                    }}
                                />
                            }

                            {document.country &&
                                <TextInput
                                    label="Issuing Authority"
                                    value={document.country_data}
                                    onChange={(text) => {
                                        let tmpDocumentsToUpload = [...documentsToUpload];
                                        tmpDocumentsToUpload[index] = {...tmpDocumentsToUpload[index], country_data: text}
                                        setDocumentsToUpload(tmpDocumentsToUpload);
                                    }}
                                />
                            }
                        </Flex>
                    )}
                </Flex>
            </ScrollView>
            <FloatingBottom>
                <SecondaryButton value="Back" onPress={() => backStep()}/>
                <Button className="mt-2" useFullColor={true} value="Proceed to Final Review"
                        color={!canUpload ? "navy-light-100/50" : "navy-light-100"} onPress={() => uploadDocuments()}
                        disabled={!canUpload} loading={loading}/>
            </FloatingBottom>
        </Flex>
    )
}