import {Platform, ScrollView, TouchableOpacity} from "react-native";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {transformTailwindToHex} from "@src/helpers";
import Button from "@components/Buttons/Button";
import React, {useEffect, useState} from "react";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {StackScreenProps} from "@react-navigation/stack";
import {AuthStackParamList} from "@src/routes";
import Header from "@components/Views/Header";
import FloatingBottom from "@components/Views/FloatingBottom";
import productionApi from "@api/productionApi";
import RNPickerSelect from "react-native-picker-select";
import {showMessage} from "react-native-flash-message";
import {CommonActions} from "@react-navigation/native";
import KitRental from "@pages/SignedIn/ProductionPayment/components/KitRental";
import Mileage from "@pages/SignedIn/ProductionPayment/components/Mileage";
import Default from "@pages/SignedIn/ProductionPayment/components/Default";

type ProductionPaymentProps = StackScreenProps<AuthStackParamList, 'ProductionPayment'>;
export default function ProductionPayment({navigation, route}: ProductionPaymentProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [canUpload, setCanUpload] = useState<boolean>(true);
    const [paymentType, setPaymentType] = useState<string>("");
    const [data, setData] = useState<object>({});

    useEffect(() => {
        let tmpCanUpload = true;

        if (paymentType == "Kit Rental") {
            if (data.extra === undefined || data.extra.rental_dates === undefined || data.extra.rental_dates.length == 0) {
                tmpCanUpload = false;
            }
        }

        if (paymentType == "Mileage") {
            if (data.extra === undefined || data.extra.total_miles === undefined || data.extra.total_miles == 0 ||
                data.extra.travel_time === undefined || data.extra.travel_time === null ||
                data.extra.travel_time.start_time === null || data.extra.travel_time.end_time === null ||
                data.extra.destination === undefined || data.extra.destination === ""
            ) {
                tmpCanUpload = false;
            }
        }

        if (paymentType != "Mileage") {
            if (data.note === undefined || data.note === null || data.note === "") {
                tmpCanUpload = false;
            }
        }

        if (data.date === undefined) {
            tmpCanUpload = false;
        }

        if (data.amount === undefined || data.amount == 0) {
            tmpCanUpload = false;
        }

        setCanUpload(tmpCanUpload);

    }, [data]);

    const save = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append('type', paymentType);

        if (data.amount !== undefined) {
            formData.append('amount', data.amount.toString());
        }

        if (data.date !== undefined) {
            formData.append('date', data.date);
        }

        if (data.note !== undefined) {
            formData.append('note', data.note);
        }

        if (data.payment_document !== undefined && data.payment_document !== null) {
            formData.append('file', data.payment_document);
        }

        if (data.extra !== undefined) {

            Object.keys(data.extra).forEach((key: string) => {

                let value = data.extra[key];

                if (key === "travel_time") {
                    formData.append(`details[travel_time][start_time]`, value.start_time);
                    formData.append(`details[travel_time][end_time]`, value.end_time);

                } else {
                    formData.append(`details[${key}]`, value);
                }
            });
        }

        try {
            let response = await productionApi.postBonuses(route.params.timecardId, formData);

            showMessage({
                message: "Success!",
                description: "Payment Saved!",
                type: 'success',
            });

            goBack()

        } catch (e) {
        }

        setLoading(false);
    }

    function goBack() {
        if (navigation.canGoBack()) {
            navigation.goBack();
            return;
        }

        navigation.dispatch(CommonActions.reset({
            index: 0,
            routes: [{name: 'Home'}],
        }))
    }

    return (
        <Flex className="bg-white flex-1" verticalAlign="between">
            <Header title="Add Payment"/>
            <FlexRow verticalAlign="center" align="between" className="h-12 border-b border-denim-20 px-5">
                <Text type="xs-medium" color="denim-50">Payment Status</Text>
                <Text type="xs-semibold" color="denim-50">Not Submitted</Text>
            </FlexRow>

            <ScrollView className="mt-5">
                <Flex className="mx-5">

                    <RNPickerSelect
                        placeholder={{label: "Payment Type", value: -1}}
                        onValueChange={value => {
                            setPaymentType(value);
                            setData({})
                        }}
                        items={[
                            {label: 'Kit Rental', value: 'Kit Rental'},
                            {label: 'Mileage', value: 'Mileage'},
                            {label: 'Bonus', value: 'Bonus'},
                            {label: 'Expenses', value: 'Expenses'},
                            {label: 'Per Diem', value: 'Per Diem'},
                            {label: 'Stunt Adjustment', value: 'Stunt Adjustment'},
                        ]}
                        style={{
                            placeholder: {
                                color: transformTailwindToHex("denim-100")
                            },
                            inputWeb: {
                                backgroundColor: "transparent",
                                padding: 12,
                                fontSize: 15,
                                borderRadius: 12,
                                borderColor: "#D0D2D8",
                                borderWidth: 1,
                                borderStyle: "solid",
                                marginBottom: 16,
                            },
                        }}
                    />

                    { paymentType == "Kit Rental" && <KitRental data={data} onUpdateData={setData} productionId={route.params.productionId}/> }

                    { paymentType == "Mileage" && <Mileage data={data} onUpdateData={setData}/> }

                    { paymentType != "Kit Rental" && paymentType != "Mileage" && paymentType != "" && <Default data={data} onUpdateData={setData}/> }

                    { paymentType == "" && <Flex className="bg-denim-5 rounded border border-denim-20 h-full" align="center" verticalAlign="center">
                        <Text>Select a Payment Type to Continue</Text>
                    </Flex> }

                </Flex>
            </ScrollView>
            <FloatingBottom>
                <SecondaryButton value="Cancel" onPress={() => goBack()}/>
                {paymentType != ""&& <Button className="mt-2" useFullColor={true} value="Save & Submit"
                        color={!canUpload ? "navy-light-100/50" : "navy-light-100"} onPress={() => save()}
                        disabled={!canUpload} loading={loading}/>}
            </FloatingBottom>
        </Flex>
    )
}