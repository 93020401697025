import React, {useEffect, useState} from "react";
import {Platform, TouchableOpacity} from "react-native";
import Upload from "@assets/icons/upload.svg";
import TextInput from "@components/Forms/TextInput";
import CurrencyInputStyled from "@components/Forms/CurrencyInput";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import DateInput from "@components/Forms/DateInput";
import TravelTime from "@pages/SignedIn/ProductionPayment/components/TravelTime";
import FlexRow from "@components/Views/FlexRow";
import {toTwoDecimal, transformTailwindToHex} from "@src/helpers";
import * as DocumentPicker from 'expo-document-picker';

type travelTimeProps = {
    start_time: string,
    end_time: string,
}

export default function Mileage({data, onUpdateData}) {

    const [paymentDocument, setPaymentDocument] = useState<any>(null);
    const [totalMiles, setTotalMiles] = useState<number | null>(null);
    const [tripDate, setTripDate] = useState<string>("");
    const [destination, setDestination] = useState<string>("");
    const [travelTime, setTravelTime] = useState<travelTimeProps|null>(null);

    useEffect(() => {

        if (data.date !== undefined) {
            setTripDate(data.date);
        }

        if (data.extra !== undefined) {
            setDestination(data.extra.destination);
            setTravelTime(data.extra.travel_time);
        }
    }, []);

    useEffect(() => {
        onUpdateData({
            date: tripDate,
            amount: (totalMiles ?? 0)*0.67,
            extra: {
                destination: destination,
                travel_time: travelTime,
                total_miles: totalMiles
            },
            payment_document: paymentDocument
        });

    }, [totalMiles, tripDate, destination, travelTime, paymentDocument]);

    const pickDocument = async () => {
        let documentResult = await DocumentPicker.getDocumentAsync({
            type: "*/*",
            multiple: false,
            copyToCacheDirectory: true
        });

        if (documentResult.type === 'cancel') {
            // User canceled picking a document, so just return
            return;
        }

        if (Platform.OS !== "web") {
            setPaymentDocument({
                name: documentResult.name,
                // If mimeType is not available, default to a generic type.
                type: (documentResult.mimeType) ? documentResult.mimeType : 'application/octet-stream',
                uri: Platform.OS === 'ios'
                    ? documentResult.uri.replace('file://', '')
                    : documentResult.uri,
            })
        } else {
            // On web, we need to convert the file into a blob
            const res = await fetch(documentResult.uri);
            const blob = await res.blob();
            setPaymentDocument(blob);
        }
    }

    return <Flex>
        <DateInput label="Trip Date" value={tripDate} defaultValue={tripDate} onChange={setTripDate}/>

        <TravelTime
            label={"Travel Time"}
            value={travelTime}
            disabled={false}
            updateValue={(value) => setTravelTime(value)}
        />

        <TextInput label="Destination" value={destination} onChange={setDestination}/>

        <Flex>
            <CurrencyInputStyled label="Total Miles" value={totalMiles} onChange={setTotalMiles}/>
            <Flex className="absolute top-4 right-10">
                <Text type="xs-medium" color="denim-50">$0.67 / Mile</Text>
            </Flex>
        </Flex>

        <TouchableOpacity onPress={() => pickDocument()}>
            {paymentDocument == null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-denim-5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("denim-100")}/>
                    <Text type="xs-medium" color="denim-100" className="ml-2">Add Document</Text>
                </FlexRow>
            }

            {paymentDocument != null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-navy-light-100/5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                    <Text type="xs-medium" color="navy-light-100" className="ml-2">Upload Complete!</Text>
                </FlexRow>
            }
        </TouchableOpacity>

        <FlexRow verticalAlign="center" align="between" className="w-full">
            <Text type="sm-medium" color="denim-50" className="my-5">Total Payment:</Text>
            <Text type="sm-medium" color={totalMiles == null ? "coral-100" : "navy-light-100"} className="my-5">${toTwoDecimal(0.67*(totalMiles ?? 0))}</Text>
        </FlexRow>
    </Flex>;
}